<template>
  <div id="preview">
    <v-card>
      <v-card-title>
        <span class="headline">{{ pageDescription }}</span>
      </v-card-title>

      <v-card-text>
        <v-dialog
          eager
          v-model="previewRecordPrompt"
          v-if="PreviewButtonFlag && Documentpath != ''"
        >
          <v-app-bar
            color="primary"
            dense
            dark
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
          >
            <v-toolbar-title>Nomination List</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon dark @click="hidePreviewRecordPrompt">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <iframe
            id="ifrm"
            :src="Documentpath"
            v-if="PreviewButtonFlag && Documentpath != ''"
            width="100%"
            height="500px"
          ></iframe>
          <v-card v-if="!PreviewButtonFlag" width="100%" height="500px">
            <v-row wrap>
              <v-col cols="12" md="12" align="center">
                <h1 class="text-danger">File not found.</h1>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>

        <v-data-table
          class="elevation-1"
          loading-text="Loading... Please wait"
          v-model="selected"
          :search="search"
          :loading="LoadingFlag"
          :item-key="tableOptions1.ItemKey"
          :single-select="tableOptions1.SingleSelectFlag"
          :headers="tableColumns1"
          :items="tableData1"
          :items-per-page="tableOptions1.ItemsPerPage"
          :footer-props="tableOptions1.FooterProps"
        >
          <template v-slot:item.ActiveStatusTxt="{ item }">
            <v-chip
              :color="getActiveStatusColor(item.ActiveStatusTxt)"
              draggable
              dark
              >{{ item.ActiveStatusTxt }}</v-chip
            >
          </template>
          <template v-slot:item.ProfilePic="{ item }">
            <img width="100" height="100" :src="item.ProfilePic" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom v-if="item.DocumentAssetPath != ''">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  icon
                  size="25px"
                  color="#00f"
                  @click="previewData(item, e)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-search
                </v-icon>
              </template>
              <span> Preview </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      tableColumns1: [],
      tableOptions1: {},
      tableData1: [],

      PreviewButtonFlag: false,
      Documentpath: "",

      LoadingFlag: false,

      selected: [],
      SearchFlag: false,
      search: "",

      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    validate() {
      console.log("validate is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.addRecordDetails();
      } else {
        console.log("some errors occured");
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.Documentpath = tr.DocumentAssetPath;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.getDocumentRecords();
    },
    getDocumentRecords() {
      console.log("getDocumentRecords called");

      var LomCode = this.recordData.LomId;
      console.log("LomCode=" + LomCode);

      if (LomCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-document/show";
        var upload = {
          UserInterface: 2,
          Lom: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.Documentpath = "";

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            var options = response.data.options;
            var TableHeader = options.TableHeader;
            var TableOptions = options.TableOptions;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableColumns1 = TableHeader;
            thisIns.tableOptions1 = TableOptions;
            thisIns.PreviewButtonFlag = TableOptions.PreviewButtonFlag;

            if (flag == 1) {
              thisIns.tableData1 = records;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
