<template>
  <div id="jcom-table-preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <h6><span class="text-danger">*</span> indicates required field</h6>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-container>
              <v-row v-if="MemberId > 0">
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <img width="100" height="auto" :src="MemberImage" />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Active Status</label>
                  <h5>
                    <v-chip
                      :color="getActiveStatusColor(StatusTxt)"
                      draggable
                      dark
                      >{{ StatusTxt }}</v-chip
                    >
                  </h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Member Name</label>
                  <h5>{{ MemberName }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Membership Id</label>
                  <h5>{{ MembershipId }}</h5>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label>Member Zone</label>
                  <h5>{{ ZoneName }}</h5>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label>Member Lom</label>
                  <h5>{{ LomName }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Member Designation</label>
                  <h5>{{ MemberDesignation }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Member Contact Number</label>
                  <h5>{{ MobileNo }}</h5>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label>Member Email</label>
                  <h5>{{ EmailId }}</h5>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label>Member Address</label>
                  <h5>{{ Address }}</h5>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label>Date of Birth</label>
                  <h5>{{ DOB }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Profile Update</label>
                  <br /><br />
                  &nbsp;
                  <v-chip
                    :color="`${ProfileUpdate ? '#0f9d58' : '#f4b400'}`"
                    draggable
                    dark
                    >{{ ProfileUpdate ? "Completed" : "Pending" }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <label>Age Proof Document Upload</label>
                  <br />
                  &nbsp;
                  <v-row>
                    <v-col cols="12" sm="12" md="4" lg="2">
                      <v-chip
                        :color="`${DocumentUpdate ? '#0f9d58' : '#f4b400'}`"
                        draggable
                        dark
                        >{{ DocumentUpdate ? "Completed" : "Pending" }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" lg="10">
                      <viewer rebuild :images="DocumentImagesArray">
                        <img
                          class="mr-4"
                          width="80"
                          height="80"
                          :src="src"
                          :key="i"
                          v-for="(src, i) in DocumentImagesArray"
                        />
                      </viewer>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h3 class="text-dark text-center">
                    Directory Information Document
                  </h3>
                  <div v-if="LomDocumentPathArray.length > 0">
                    <h6
                      v-for="(row, index) in LomDocumentPathArray"
                      :key="index"
                    >
                      {{ index + 1 }}.
                      <a :href="row" target="_blank">
                        Download Directory Information Document {{ index + 1 }}
                      </a>
                    </h6>
                  </div>
                  <div v-else>
                    <v-chip color="#f4b400" draggable dark>Pending </v-chip>
                    &nbsp;
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click.prevent="uploadDocumentPrompt = true"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <br />
            <v-row>
              <v-col
                cols="12"
                lg="12"
                sm="12"
                md="12"
                v-if="ReasonHistory != ''"
              >
                <v-divider />
                <h3 class="text-center">Reason History</h3>
                <p class="font-size-h6 mt-3" v-html="ReasonHistory"></p>
              </v-col>
              <v-col cols="12" lg="12" sm="12" md="12">
                <v-divider />
                <label>
                  <h3><span class="text-danger">*</span> Reason</h3>
                </label>
                <v-textarea
                  outlined
                  v-model="Reason"
                  :rules="ReasonRules"
                  name="input-7-4"
                  label="Reason"
                  value=""
                ></v-textarea>
              </v-col>
              <v-col cols="12" lg="12" sm="12" md="12" class="text-center">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="confirmAlert(1)"
                  color="success"
                  elevation="30"
                  medium
                  class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                >
                  Approved
                </v-btn>
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="confirmAlert(3)"
                  color="error"
                  elevation="30"
                  medium
                  class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                >
                  Not Accepted
                </v-btn>
                <v-btn
                  @click="closePrompt"
                  color="#F44336"
                  elevation="30"
                  medium
                  class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                >
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
    <v-dialog v-model="uploadDocumentPrompt" persistent max-width="70%">
        <add-lom-document
          pageTitle="Upload Nomination List"
          pageDescription="Upload Nomination List"
          :recordData="recordData"
          :addRecordPrompt="uploadDocumentPrompt"
          @hideAddRecordPrompt="hideUploadRecordPrompt"
          v-if="uploadDocumentPrompt"
        ></add-lom-document>
      </v-dialog>

  </div>
</template>
    
    <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import AddLomDocument from "@/view/pages/erp/reports/AddLomDocument.vue";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
    AddLomDocument,
  },
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: false,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      MemberId: "",
      MemberImage: "",
      MemberName: "",
      MembershipId: "",
      ZoneName: "",
      LomName: "",
      MemberDesignation: "",
      MobileNo: "",
      EmailId: "",
      Address: "",
      DOB: "",
      YearwiseOfficeHeldId: "",
      ProfileUpdate: "",
      DocumentUpdate: "",
      DocumentImage: "",
      DocumentImagesArray: [],
      LomDocumentPathArray: [],
      StatusTxt: "",
      ReasonHistory: "",

      uploadDocumentPrompt: false,

      Reason: "",
      ReasonRules: [(v) => !!v || "Reason is required"],
      menu1: false,

      ActiveStatus: 1,

      PageInfo: {},
      PageTitle: "Directory Information Preview",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.LaunchDate = todayDate;
        this.pageData();
      }
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_table",
        Action: "preview",
      };
      // this.getPageDetails("PageInfo", {}, url1, condition1);
      this.LoadingFlag = false;

      var recordData = this.recordData;
      console.log("recordData = " + JSON.stringify(recordData));
      this.getTableRecords();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt", this.ResultFlag);
    },
    hideUploadRecordPrompt() {
      console.log("hideUploadRecordPrompt called");
      this.uploadDocumentPrompt = false;
      this.$emit("hidePreviewRecordPrompt", true);
    },
    getTableRecords() {
      console.log("getTableRecords called");

      this.MemberId = this.recordData.MemberId;
      this.MemberImage = this.recordData.MemberImagePath;
      this.MemberName = this.recordData.MemberName;
      this.MembershipId = this.recordData.MembershipId;
      this.ZoneName = this.recordData.Zone;
      this.LomName = this.recordData.LOM;
      this.MemberDesignation = this.recordData.Designaton;
      this.MobileNo = this.recordData.Mobile;
      this.EmailId = this.recordData.Email;
      this.Address = this.recordData.Address;
      this.DOB = this.recordData.DOB;
      this.YearwiseOfficeHeldId = this.recordData.YearwiseOfficeHeldId;
      this.LomDocumentPathArray = this.recordData.LomDocumentPathArray;
      this.ProfileUpdate = this.recordData.MemberProfileUpdated;
      this.StatusTxt = this.recordData.YearwiseOfficeHeldStatusTxt;
      this.ReasonHistory = this.recordData.Reason;

      var server_url = companyConfig.apiURL;
      var add_url = server_url + "assets/images/white-user.png";
      console.log({ add_url });
      this.MemberImage = this.MemberImage == "" ? add_url : this.MemberImage;
      console.log("member image" + this.MemberImage);
      this.DocumentUpdate = this.recordData.MemberDocumentFlag;
      this.DocumentImage = this.recordData.MemberDocumentPath;
      console.log("DocumentImage" + this.DocumentImage);
      this.DocumentImagesArray = this.recordData.MemberDocumentPathArray;
    },
    confirmAlert(status) {
      console.log({ status });
      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.updateActiveStatus(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
    updateActiveStatus(status) {
      console.log("updateActiveStatus is called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      var Status = status;
      var Reason = this.Reason;
      var YearwiseOfficeHeldId = this.YearwiseOfficeHeldId;
      console.log({ Status }, { Reason }, { YearwiseOfficeHeldId });

      if (validate1) {
        this.LoadingFlag = true;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/status-update";
        var upload = {
          UserInterface: 1,
          Status: Status,
          Reason: Reason,
          YearwiseOfficeHeld: YearwiseOfficeHeldId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, true);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
    <style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>